<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        :title="titleName"
        :filter="filter"
        @sidebar="sidebarFilter()"
        :hasDropdown="false"
        :hasSearch="false"
        :hasExport="hasExport"
        @export="exportModal"
      />
      <div class="mt-3">
        <b-tabs v-model="tabIndex" @input="handleTabsChange">
          <b-tab title="Service Used">
            <ServiceUsed ref="serviceUsed" v-if="tabIndex == 0" />
          </b-tab>
          <b-tab title="Service Outstanding">
            <ServiceOutstanding ref="serviceOutstanding" v-if="tabIndex == 1" />
          </b-tab>
        </b-tabs>
      </div>
    </div>
    <ModalInputEmail
      ref="ModalInputEmail"
      :form="filterExportFile"
      @closeModal="clearExportFilter"
      @changeEmail="(val) => (filterExportFile.email = val)"
      @submit="handleExport"
    />
  </div>
</template>

<script>
const moment = require("moment");
// import "moment-range";
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import ModalInputEmail from "@/components/customer/ModalInputEmail";
import ServiceUsed from "@/components/serviceChart/Used";
import ServiceOutstanding from "@/components/serviceChart/Outstanding";

export default {
  components: {
    OtherLoading,
    HeaderPanel,
    ModalInputEmail,
    ServiceUsed,
    ServiceOutstanding,
  },
  data() {
    return {
      tabIndex: 0,
      items: [],
      filter: {
        year_from: moment().subtract(6, "day").year(),
        month_from: moment().subtract(6, "day").month() + 1,
        day_from: moment().subtract(6, "day").date(),
        year_to: moment().year(),
        month_to: moment().month() + 1,
        day_to: moment().date(),
        date_from: moment().subtract(6, "day").format(),
        date_to: moment().format(),
      },
      filterExportFile: {
        email: "",
      },
      isLoading: true,
      isBusy: false,
      dashBoardData: [],
      dashBoardLabel: [],
      hasExport: true,
    };
  },
  created() {
    // if (this.$route.query.tabIndex) {
    //   this.tabIndex = parseInt(this.$route.query.tabIndex);
    // }
  },
  async mounted() {
    // if (this.$route.query.tabIndex != this.tabIndex) {
    //   this.$router.replace({
    //     query: { tabIndex: this.tabIndex },
    //   });
    // }
    this.isLoading = false;
  },
  watch: {
    tabIndex(val) {
      // if (val == 0) this.hasExport = true;
      // else
      this.hasExport = true;
    },
  },
  computed: {
    titleName() {
      let titile = "";
      if (this.tabIndex == 0) titile = "Service used";
      else if (this.tabIndex == 1) titile = "Service Outstanding";
      return titile;
    },
  },
  methods: {
    handleTabsChange(a) {
      // this.$router.replace({
      //   query: { tabIndex: a },
      // });
    },
    async exportExcel(chartData) {
      const { id, name } = chartData;
      let payload = {
        report_life_cycle_type_id: id,
        ...this.filter,
      };
      this.isLoading = true;
      const res = await this.$report.post(`/dashboard/export`, payload, {
        responseType: "blob",
      });

      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", name + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.isLoading = false;
    },
    exportModal() {
      this.handleExport();
    },
    handleMinDate() {
      let diff = this.$moment(this.filter.date_to).diff(
        this.$moment(this.filter.date_from),
        "min"
      );
      if (diff < 0) this.filter.date_to = "";
    },
    async handleExport() {
      var res = null,
        fileName = "";
      this.$bus.$emit("showLoading");
      switch (this.tabIndex) {
        case 0:
          // payload.email = this.filterExportFile.email;
          res = await this.$report.post(
            `/dashboard/service/burn_summary/report`,
            { ...this.$refs.serviceUsed.filterFreeze }
          );
          fileName = "burn_summary-" + new Date();
          break;

        case 1:
          // payload.email = this.filterExportFile.email;
          res = await this.$report.post(
            `/dashboard/service/outstanding/report`,
            { ...this.$refs.serviceOutstanding.filterFreeze }
          );
          fileName = "outstanding-" + new Date();
          break;

        default:
          break;
      }
      if (this.tabIndex == 0 || this.tabIndex == 1) {
        if (res.data.result)
          this.confirmAlert({
            message: "Do you want to download the file now?",
            title: "Success !",
            icon: "success",
            confirmButtonText: "Go To Page",
            cancelButtonText: "Close",
          }).then((val) => {
            if (val.isConfirmed) {
              // this.deleteItem(id);
              const routeData = this.$router.resolve({
                name: "Customer Report List",
              });
              window.open(routeData.href, "_blank");
            }
          });
        else this.errorAlert(res.data.message);
      } else {
        let data = res;

        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      this.$bus.$emit("hideLoading");
    },
    clearExportFilter() {
      this.filterExportFile.email = "";
    },
    sidebarFilter() {
      if (this.tabIndex == 0) this.$refs.serviceUsed.$refs.filter.show();
      else if (this.tabIndex == 1)
        this.$refs.serviceOutstanding.$refs.filter.show();
    },
  },
};
</script>

<style lang="scss" scoped>
.alert-error {
  text-align: center;
  padding: 20px;
  color: #000;
}
::v-deep .nav.nav-tabs {
  background-color: white;
  border: none;
  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: transparent !important;
    color: var(--primary-color) !important;
    border-bottom: 4px solid var(--primary-color) !important;
  }
  .nav-link {
    border: none;
    border-color: #d8dbe0 !important;
    border-right: 1px solid #cfcfcf;
    min-width: 150px;
    text-align: center;
    color: #333 !important;
  }
  :last-child .nav-link {
    border-right: 0 !important;
  }
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .btn-export {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  text-decoration: underline;
  border: 0 !important;
  padding-right: 0 !important;
}

::v-deep .chart-title {
  color: var(--font-color);
  margin-bottom: 0.5rem !important;
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-weight: 700 !important;
  .title {
    font-size: 20px;
  }
  .sub-titile {
    color: #717171 !important;
    font-weight: normal !important;
    font-size: 16px;
  }
}
</style>
